// Header principal
.header-full {
  position: fixed;
  z-index: $z_index_header;
  top: 0;
  left: 0;
  float: left;
  width: 100%;
  background: #fff;
  padding: 20px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.26);
  transition: 0.5s ease;

  // MOBILE
  @include mq(s) {
    padding-top: 40px;
    padding-bottom: 10px;
    text-align: center;
  }
}

// Conteúdo (Container)
.header-full .container {
  position: static;
}
.header-full .header-content {
  position: relative;
  padding: 0 10px;

  // MOBILE
  @include mq(s) {
    position: static;
  }
}

// Logo
.header-full .header-logo {
  display: block;
  float: left;
  max-width: 180px;
  margin-top: 5px;

  // TABLET
  @include mq(m) {
    max-width: 185px;
  }

  // MOBILE
  @include mq(s) {
    float: none;
    display: inline-block;
    vertical-align: top;
    max-width: 110px;
    margin-top: 0px;
  }

  // Logo branco
  &.white-logo {
    display: none;
  }
}

// Direita
.header-full .header-direita {
  float: right;
  margin-top: 30px;

  // MOBILE
  @include mq(s) {
    height: 0px;
  }
}

// Botões
.header-full .header-botoes {
  float: right;
  text-align: right;
  margin-left: 40px; /* 
  margin-top: 30px; */

  // MOBILE
  @include mq(s) {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    display: none;
  }

  .btn {
    margin-right: 10px;

    // MOBILE
    @include mq(s) {
      font-size: 12px;
      margin: 5px;
      padding: 10px 15px;
    }
    // Botão pequeno
    &.btn-sm {
      padding: 12px 25px;
    }
  }
}

// Telefones
.header-full .header-telefones {
  position: absolute;
  top: -20px;
  right: 10px;
  background-image: linear-gradient(
    to right top,
    #007ccf,
    #0071c4,
    #0066ba,
    #005caf,
    #0051a4
  );
  padding: 5px 10px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  // MOBILE
  @include mq(s) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 3px;
    background: $cor_fundo2;
    border-radius: 0px;
    width: 100%;
    text-align: center;
    white-space: nowrap;
  }

  // Item
  a {
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    margin: 0 5px;

    // TABLET
    @include mq(m) {
      font-size: 14px;
    }

    // MOBILE
    @include mq(s) {
      font-size: 14px;
      color: $cor_fonte_escura;
    }

    i {
      margin-right: 3px;

      // MOBILE
      @include mq(s) {
        color: $cor_primaria;
      }
    }
  }
}

// ---------------------------- HOME ---------------------------- //
body.home-full {
  padding-top: 0px;
}

// Header
.home-full .header-full:not(.active) {
  background: none;
  background-image: linear-gradient(to bottom, rgba(#000, 0.5), rgba(#000, 0));
  box-shadow: none;
  padding-bottom: 40px;

  // MOBILE
  @include mq(s) {
    padding-top: 45px;
  }
}

// Logo
.home-full .header-full:not(.active) .header-logo {
  // Logo COLORIDO
  &.colored-logo {
    display: none;
  }

  // Logo BRANCO
  &.white-logo {
    display: block;
    // MOBILE
    @include mq(s) {
      display: inline-block;
    }
  }
}

// Telefones
.home-full .header-full:not(.active) .header-telefones {
  // MOBILE
  @include mq(s) {
    padding-top: 5px;
    padding-bottom: 5px;
    background: rgba(#fff, 0.1);
  }

  a {
    // MOBILE
    @include mq(s) {
      color: #fff;
    }
  }
}

// Botão
.home-full .header-full:not(.active) .outline {
  border: 1px solid #fff;
  color: #fff;

  &:hover,
  &:focus,
  &:active,
  &.active {
    background: #fff;
    color: $cor_primaria;
  }
}

// Menu lateral
.home-full .header-full:not(.active) .lateral .fdx-menu-1-level > li > a {
  color: #fff;

  &:hover {
    color: #fff;
  }

  &:before {
    background: #fff;
  }
}

// Botão menu mobile
.home-full .header-full:not(.active) .menu-btn-mobile {
  border-color: #fff;

  &::before,
  &::after {
    background: #fff;
  }
}

// Slide
.home-full .slide-item .slide-image {
  height: 100vh;
  min-height: 600px;

  // TABLET
  @include mq(m) {
    height: 90vh;
  }

  // MOBILE
  @include mq(s) {
    height: 80vh;
    min-height: 400px;
  }
}
.home-full .slide-item .slide-content {
  padding-top: 50px;
}
