/* Preload images */
body:after {
  //content: url(../images/close.png) url(../images/loading.gif) url(../images/prev.png) url(../images/next.png);
  display: none;
}

body.lb-disable-scrolling {
  overflow: hidden;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;

  /* Image border */
  border: 2px solid #fff;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;

  /* Background color behind image.
    This is visible during transitions. */
  background-color: white;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  //background: url(../images/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
}

.lb-prev,
.lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-prev::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  transform: rotate(225deg);
  left: 20px;
}

.lb-nav a.lb-prev:hover {
  opacity: 1;
}

.lb-nav a.lb-next {
  position: relative;
  width: 64%;
  right: 0;
  float: right;
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-next::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  transform: rotate(45deg);
  right: 20px;
}

.lb-nav a.lb-next:hover {
  opacity: 1;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
  font-family: "Montserrat", sans-serif;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}

.lb-data .lb-caption a {
  color: #4ae;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}

.lb-data .lb-close {
  position: relative;
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  outline: none;
  opacity: 0.7;
  cursor: pointer;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;

  &::before,
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: #999999;
    position: absolute;
    top: 50%;
    -webkit-transition: background 0.3s ease-out;
    transition: background 0.3s ease-out;
  }

  &:hover::before,
  &:hover::after {
    background: #ffffff;
  }

  &::before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &::after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

// MOBILE
@media screen and (max-width: 760px) and (orientation: landscape) {
  .lightbox {
    position: fixed;
  }
  .lb-outerContainer {
    display: inline-block;
    width: initial !important;
    height: initial !important;
  }
  .lb-image {
    width: initial !important;
    height: initial !important;
    max-width: 100% !important;
    max-height: calc(100vh - 35px) !important;
  }
  .lb-dataContainer {
    width: initial !important;
  }
  .lb-details {
    text-align: center !important;
    width: 100% !important;
  }
  .lb-close {
    display: none !important;
  }
  .lb-number {
    display: initial !important;
  }
  #lightbox {
    top: 0 !important;
  }
  #lightboxOverlay {
    width: 100% !important;
    height: 150% !important;
    position: fixed;
  }
}
