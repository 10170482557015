// Box-sizing
*,
*::after,
*::before {
  box-sizing: border-box;
}

// Remove outline de foco do firefox e outros browsers
:focus {
  outline: none;
}
::-moz-focus-inner {
  border: 0;
}

// Cor do texto selecionado
::selection {
  background: rgba($cor_primaria, 0.5);
}
::-moz-selection {
  background: rgba($cor_primaria, 0.5);
}

// Remove o fundo azul de itens clicados no chrome para android
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

// Body
body {
  background: #fff;
  font-family: $fonte_geral;
  font-size: 16px;
  line-height: 1.4;
  color: $cor_fonte_clara;
  padding-top: 107px;

  // MOBILE
  @include mq(s) {
    padding-top: 86px;
  }
}

// Fix imagens
img {
  display: block;
  max-width: 100%;
}

// Seções
.secao {
  position: relative;
  float: left;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;

  // MOBILE
  @include mq(s) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  // Corrige o bug do scroll horizontal nas animações
  .container {
    overflow: hidden;
  }
}

.visible-desktop {
  // MOBILE
  @include mq(s) {
    display: none !important;
  }
}
.visible-mobile {
  display: none !important;

  // MOBILE
  @include mq(s) {
    display: inline-block !important;
  }
}
