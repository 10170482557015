// Seção
.home-sobre {
  //background: $cor_fundo2;

  .titulo {
    text-align: left;

    // MOBILE
    @include mq(s) {
      text-align: center;
    }

    &::after {
      right: inherit;

      // MOBILE
      @include mq(s) {
        right: 0;
      }
    }
  }
}

// Esquerda
.home-sobre-left {
  padding-right: 10px;

  // MOBILE
  @include mq(s) {
    padding-right: 0px;
  }

  // Texto
  .texto {
    text-align: left;
    font-size: 18px;
    font-weight: 300;
    color: $cor_fonte_escura;

    // MOBILE
    @include mq(s) {
      font-size: 16px;
    }
  }

  // Botão
  .btn-container {
    margin-top: 10px;
    // MOBILE
    @include mq(s) {
      text-align: center !important;
    }
  }
}

// Direita (Foto)
.home-sobre-right {
  overflow: hidden;
  border-radius: 5px;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 2;
    top: 26px;
    right: 26px;
    bottom: 26px;
    left: 26px;
    border: 2px solid rgba(#fff, 0.4);
    transition: 0.5s ease-out;

    // MOBILE
    @include mq(s) {
      top: 25px;
      right: 25px;
      bottom: 25px;
      left: 25px;
    }
  }

  // MOBILE
  @include mq(s) {
    width: 100%;
    margin: 0px;
    margin-bottom: 15px;
  }

  img {
    transition: 0.5s ease-out;
  }
  &:hover img {
    transform: scale(1.1);
  }
}
