// Slide
.slide-home {
  float: left;
  width: 100%;

  // Modifica as cetas do SLICK para o slide
  .slick-prev,
  .slick-next {
    width: 80px;

    // MOBILE
    @include mq(s) {
      width: 50px;
    }

    &::before {
      height: 30px;
      width: 30px;

      // MOBILE
      @include mq(s) {
        height: 20px;
        width: 20px;
      }
    }
  }
  .slick-dots {
    bottom: 10px;

    // MOBILE
    @include mq(s) {
      opacity: 0;
    }
  }
}

// Slide ITEM (Background Image)
.slide-item {
  position: relative;
  display: block;
  float: left;
  width: 100%;
  height: auto;
  overflow: hidden;
  background: #fff;
  text-align: center;

  &[href="#"] {
    cursor: default;
  }

  // Slide IMAGEM
  .slide-image {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 450px;

    // TABLET
    @include mq(m) {
      height: 350px;
    }

    // MOBILE
    @include mq(s) {
      height: 300px;
    }
  }
}

// Slide ITEM (Imagem padrão) OBS: Está desabilitado por padrão
.slide-item-auto {
  position: relative;
  display: block;
  float: left;
  width: 100%;
  height: auto;
  overflow: hidden;
  background: #fff;
  text-align: center;

  &[href="#"] {
    cursor: default;
  }

  // Slide IMAGEM
  .slide-image {
    display: inline-block;
    vertical-align: bottom;
  }
}

// Slide MÁSCARA
.slide-mask {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(#000, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

// Slide CONTEÚDO
.slide-content {
  width: 100%;
  max-width: $largura_desktop;
  padding: 0 10px;
  text-align: center;
  display: none;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  // TABLET
  @include mq(m) {
    max-width: $largura_tablet;
  }

  // MOBILE
  @include mq(s) {
    max-width: $largura_smartphone;
    padding: 0 20px;
  }

  &.left {
    text-align: left;

    // MOBILE
    @include mq(s) {
      text-align: center;
    }
  }
}

// Slide TÍTULO
.slide-titulo {
  color: #fff;
  font-family: $fonte_titulo;
  font-size: 65px;
  font-weight: normal;
  line-height: 0.8;
  text-transform: uppercase;
  margin-bottom: 15px;
  text-shadow: 2px 2px 2px rgba(#000, 0.2);
  //letter-spacing: 3px;

  // TABLET
  @include mq(m) {
    font-size: 54px;
  }

  // MOBILE
  @include mq(s) {
    font-size: 38px;
  }
}

// Slide TEXTO
.slide-texto {
  color: #fff;
  font-family: $fonte_geral;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.4;
  text-shadow: 2px 2px 2px rgba(#000, 0.2);

  // TABLET
  @include mq(m) {
    font-size: 18px;
  }

  // MOBILE
  @include mq(s) {
    font-size: 16px;
  }
}

// Slide BOTÃO
.slide-btn {
  text-align: center;
  margin-top: 20px;

  .btn {
    // MOBILE
    @include mq(s) {
      font-size: 12px;
      padding: 12px 25px;
    }
  }
}
.slide-content.left .slide-btn {
  text-align: left;

  // MOBILE
  @include mq(s) {
    text-align: center;
  }
}

@-webkit-keyframes slide-fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes slide-fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.slide-fadeInUp {
  -webkit-animation-name: slide-fadeInUp;
  animation-name: slide-fadeInUp;
}

// Divisor
.divider {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    right: 0;
    //bottom: 0;
    border-style: solid;
    border-width: 73px 456px 32px 0;
    border-color: transparent rgba(#007bff, 0.5) transparent transparent;

    // MOBILE
    @include mq(s) {
      bottom: inherit;
    }
  }

  > span {
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    position: relative;
  }

  &.bottom {
    margin-top: -100px;

    // MOBILE
    @include mq(s) {
      margin-top: -50px;
    }
  }

  &.bottom.left {
    span {
      border-width: 100px 0 0 100vw;
      border-color: transparent transparent transparent #fff;

      // MOBILE
      @include mq(s) {
        border-width: 50px 0 0 100vw;
      }
    }
    &.grey span {
      border-color: transparent transparent transparent $cor_fundo2;
    }
  }

  &.bottom.right {
    span {
      border-width: 0 0 100px 100vw;
      border-color: transparent transparent #fff transparent;

      // MOBILE
      @include mq(s) {
        border-width: 0 0 50px 100vw;
      }
    }
    &.grey span {
      border-color: transparent transparent $cor_fundo2 transparent;
    }
  }

  &.up {
    margin-bottom: -100px;

    // MOBILE
    @include mq(s) {
      margin-bottom: -50px;
    }
  }

  &.up.left {
    span {
      border-width: 100px 100vw 0 0;
      border-color: #fff transparent transparent transparent;

      // MOBILE
      @include mq(s) {
        border-width: 50px 100vw 0 0;
      }
    }
    &.grey span {
      border-color: $cor_fundo2 transparent transparent transparent;
    }
  }

  &.up.right {
    span {
      border-width: 0 100vw 100px 0;
      border-color: transparent #fff transparent transparent;

      // MOBILE
      @include mq(s) {
        border-width: 0 100vw 50px 0;
      }
    }
    &.grey span {
      border-color: transparent $cor_fundo2 transparent transparent;
    }
  }
}
