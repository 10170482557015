// Seção
.blocos-home {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;

  // Máscara
  .mascara {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to right,
      #006ec1,
      #0068bb,
      #0062b6,
      #005cb0,
      #0056aa
    );
    opacity: 0.9;
  }

  // Título
  .titulo {
    color: #fff;
  }
}

// Carrosel
.carrosel-blocos {
  .slick-prev,
  .slick-next {
    &::before {
      border-color: rgba(#fff, 0.8);
    }
    &:hover::before,
    &:focus::before {
      border-color: $cor_primaria;
    }
  }
}

// Bloco item
.bloco-home {
  width: 100%;
  margin: 20px 30px;
  text-align: center;

  // MOBILE
  @include mq(s) {
    margin: 10px 10px;
  }

  // TABLET
  @include mq(m) {
    margin: 10px 20px;
  }

  // Ícone
  .bloco-home-icon {
    position: relative;
    display: inline-block;
    background: rgba(#fff, 0.2);
    width: 120px;
    height: 120px;
    line-height: 145px;
    border-radius: 50%;
    text-align: center;
    margin-bottom: 20px;
    transition: 0.3s ease-out;

    &::before {
      content: "";
      display: block;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background: rgba(#fff, 0.5);
      opacity: 0;
    }

    i {
      font-size: 50px;
      color: #fff;
      transition: 0.3s ease-out;
    }
  }
  &:hover .bloco-home-icon::before {
    animation: bloco-pulse 0.5s;
  }

  // Imagem
  .bloco-home-img {
    display: inline-block;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-top: 10px;
    margin-bottom: 20px;
    overflow: hidden;
  }

  // Título
  .bloco-home-titulo {
    font-family: $fonte_geral;
    color: #fff;
    font-size: 16px;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 10px;
    transition: 0.3s ease-out;
  }
  &:hover .bloco-home-titulo {
    //color: $cor_primaria;
  }

  // Texto
  .bloco-home-texto {
    color: #fff;
    font-weight: 300;
  }
}

@keyframes bloco-pulse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(1.3);
  }
}
