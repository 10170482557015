// Foto
.servico-detalhe-foto {
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 5px;

  // MOBILE
  @include mq(s) {
    margin-bottom: 20px;
  }
}

// Texto
.servico-detalhe-texto {
  // BOTÃO
  .btn-container {
    float: none;

    // MOBILE
    @include mq(s) {
      text-align: center;
    }
  }
}

.servico-bloco1 {
  background: $cor_fundo2;
}

.secao-degrade {
  padding-top: 0px;
  background-image: linear-gradient(
    to bottom,
    #f1f3f4,
    #f5f6f7,
    #f8f9fa,
    #fcfcfc,
    #ffffff
  );
}
