// Footer geral
.footer {
  position: relative;
  float: left;
  width: 100%;
  padding-top: 40px;
  background: darken($cor_primaria, 6%);
  overflow: hidden;

  // MOBILE
  @include mq(s) {
    padding-bottom: 0px;
  }
}

// Conteúdo container
.footer-content {
  margin-bottom: 40px;
}

// Coluna
.footer-bloco {
  &:not(:last-child) {
    // MOBILE
    @include mq(s) {
      margin-bottom: 40px;
    }
  }

  // Título
  > h2 {
    font-family: $fonte_titulo;
    color: #fff;
    font-size: 46px;
    font-weight: normal;
    line-height: 1.2;
    text-transform: uppercase;
    margin-bottom: 20px;

    // MOBILE
    @include mq(s) {
      font-size: 34px;
    }
  }

  // Lista links
  .footer-lista {
    // Item
    li {
      float: left;
      width: 50%;
      margin-bottom: 15px;
      font-size: 15px;
      font-weight: 300;
      line-height: 1.2;
      color: #fff;

      &:last-child {
        margin-bottom: 0px;
      }

      // TABLET
      @include mq(m) {
        width: 100%;
      }

      // MOBILE
      @include mq(s) {
        width: 100%;
      }
    }

    // Link
    a {
      color: #fff;
      transition: 0.2s ease-out;

      &:hover {
        color: $cor_secundaria;
      }
      /* &.nowrap {
        white-space: nowrap;
      } */
    }

    // Ícone
    i {
      margin-right: 10px;
    }
  }
}

// Mapa do site
.footer-mapa {
}

// Newsletter
.footer-newsletter {
  padding-right: 40px;
  padding-left: 40px;

  // TABLET
  @include mq(m) {
    padding-right: 20px;
    padding-left: 0px;
  }

  // MOBILE
  @include mq(s) {
    padding-left: 0px;
    padding-right: 0px;
  }

  // Texto
  .texto {
    font-size: 15px;
    font-weight: 300;
    color: #fff;
    margin-bottom: 20px;
  }

  // Campo
  .campo-container {
    float: left;
    width: calc(100% - 120px);
    margin: 0px;

    // TABLET
    @include mq(m) {
      width: 100%;
      margin-bottom: 10px;
    }

    // MOBILE
    @include mq(s) {
      width: 100%;
      margin-bottom: 10px;
    }

    // Campo
    .campo {
      height: 40px;
      border: none;

      // DESKTOP
      @include mq(l) {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }

  // Botão
  .btn {
    float: left;
    width: 120px;
    height: 40px;
    padding: 0px;
    font-size: 14px;

    // DESKTOP
    @include mq(l) {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    // TABLET
    @include mq(m) {
      width: 100%;
    }

    // MOBILE
    @include mq(s) {
      width: 100%;
    }
  }
}

// Atendimento
.footer-atendimento {
  padding-left: 40px;

  // TABLET
  @include mq(m) {
    padding-left: 20px;
  }

  // MOBILE
  @include mq(s) {
    padding-left: 0px;
  }

  .footer-lista li {
    width: 100%;
  }
}

// Barra footer
.barra-footer {
  background: darken($cor_primaria, 8%);
  padding: 10px 0;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.2;
  color: #fff;

  [class^="grid-"]:not(.redes-sociais) {
    padding-top: 10px;
  }

  // Copyright
  .copyright {
    text-align: center;
  }

  // Desenvolvimento
  .development {
    text-align: right;

    // MOBILE
    @include mq(s) {
      text-align: center;
    }

    a {
      color: #fff !important;
    }
  }
}

// Redes sociais
.redes-sociais {
  text-align: left;

  // MOBILE
  @include mq(s) {
    text-align: center;
  }

  // Item
  a {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 43px;
    border-radius: 50%;
    text-align: center;
    margin: 0 5px;
    background: $cor_primaria;
    transition: 0.3s ease-out;

    &:hover {
      background: #fff;
    }

    i {
      color: #fff;
      font-size: 20px;
      transition: 0.3s ease-out;
    }
    &:hover i {
      color: $cor_primaria;
    }
  }
}

// Voltar ao topo
.gotop {
  position: fixed;
  z-index: $z_index_gotop;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba($cor_primaria, 0.8);
  cursor: pointer;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  transform: translate3d(0, 20px, 0);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-out;

  &:hover {
    background: rgba($cor_primaria, 1);
  }

  &.active {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    visibility: visible;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    display: block;
    width: 15px;
    height: 15px;
    border: 2px solid #fff;
    transform: rotate(45deg);
    border-bottom: none;
    border-right: none;
    margin-top: 20px;
  }
}

// Contatos laterais
.contatos-laterais {
  position: fixed;
  z-index: $z_index_gotop;
  top: 40%;
  right: 20px;
  /* transform: translate3d(20px, 0, 0);
  opacity: 0;
  visibility: hidden; */
  transition: 0.3s ease-out;

  // MOBILE
  @include mq(s) {
    top: inherit;
    right: inherit;
    left: 15px;
    bottom: 38px;
    transform: translate3d(0, 20px, 0);
  }

  /* &.active {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    visibility: visible;
  } */

  // Item
  > a {
    position: relative;
    display: inline-block;
    margin: 5px 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    background-color: #00ce6e;
    color: #fff;
    font-size: 32px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);

    // MOBILE
    @include mq(s) {
      margin: 0 5px;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: rgba(#00ce6e, 0.5);
      opacity: 0;
    }
    &:hover::before {
      animation: contatos-pulse 0.5s;
    }
  }
}
@keyframes contatos-pulse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(1.5);
  }
}

.section-divider {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  height: 50px;
  background: none;

  svg {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 100%;

    path {
      fill: #fff;
    }
  }

  &.small-bottom {
    //transform: rotate(180deg);

    svg {
      bottom: 49px;
    }

    path {
      fill: #fff;
    }
  }

  &.small-bottom-footer {
    background: #fff;

    svg path {
      fill: darken($cor_primaria, 6%);
    }
  }

  &.small-bottom-footer2 {
    background: $cor_fundo2;

    svg path {
      fill: $cor_secundaria;
    }
  }

  &.small-bottom-up {
    svg path {
      fill: #222;
    }
  }

  &.small-bottom-down {
    transform: rotate(180deg);

    svg path {
      fill: #222;
    }
  }
}
