// Cliente bloco item
.cliente-item {
  display: block;
  margin: 5px 10px;
  border: 1px solid rgba(#000, .1);
  border-radius: 8px;
  overflow: hidden;
  
  // Imagem
  img {
    filter: grayscale(100%);
    transition: .3s ease-out;
  }
  &:hover {
    position: relative;
    z-index: 2;
    transform: scale(1.1);

    img {
      filter: grayscale(0%);
    }
  }
}
